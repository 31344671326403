import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import '@/assets/css/normalize.css'
import '@/assets/css/reset.css'
import '@/assets/css/common.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import nprogress from 'nprogress'
import 'nprogress/nprogress.css'
import Vant from 'vant'
import 'vant/lib/index.css'

Vue.use(Vant)

Vue.use(ElementUI)

Vue.use(VueAwesomeSwiper)

Vue.prototype.$nprogress = nprogress

// 配置axios
axios.defaults.baseURL = ''
axios.interceptors.response.use(function (res) {
  return res.data
}, function (error) {
  console.log(error)
})
Vue.prototype.$http = axios

/**
 * 标题配置
 */
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  el: '#app',
  render: h => h(App)
}).$mount('#app')
