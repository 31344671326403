import Vue from 'vue'
import VueRouter from 'vue-router'
import nprogress from 'nprogress'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  redirect: '/about'
}, {
  path: '/index',
  name: 'Index',
  component: () => import('@/views/Index/IndexView.vue'),
  meta: {
    title: '随心启程'
  }
}, {
  path: '/note',
  name: 'Note',
  component: () => import('@/views/Note/IndexView.vue'),
  meta: {
    title: '学习笔记'
  }
}, {
  path: '/life',
  name: 'Life',
  component: () => import('@/views/Life/IndexView.vue'),
  meta: {
    title: '生活分享'
  }
}, {
  path: '/link',
  name: 'Link',
  component: () => import('@/views/Link/IndexView.vue'),
  meta: {
    title: '友情链接'
  }
}, {
  path: '/about',
  name: 'About',
  component: () => import('@/views/About/IndexView.vue'),
  meta: {
    title: '关于'
  }
}]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  nprogress.start()
  next()
})

export default router
